import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import PropTypes from 'prop-types';

const TechnologyItems = ({ items }) => {

  return (
    <div className="technology-items">
      {items.map((item, index) => {
        const count = ++index;
        const image = getImage(item.image.localFile);
        return (
          <div className="technology-items__item" key={index}>
            <div className="technology-item__image-wrapper">
              <div
                className="technology-item__image-owerflow"
                data-scroll=""
                data-scroll-speed="-0.5"
              >
                <GatsbyImage image={image} 
                  className="technology-item__image"
                  alt={item.title}
                />
              </div>
            </div>
            <div className="technology-item__info">
              <span className="technology-item__info-count">
                {'0' + count + '.'}
              </span>
              <h3 className="technology-item__info-title">{item.title}</h3>
              <p className="technology-item__info-description">
                {item.description}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

TechnologyItems.propTypes = {
  items: PropTypes.array,
};

export default TechnologyItems;
