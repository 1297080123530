import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import PropTypes from 'prop-types';

import VideoIcon from '../../../../../svg/video_icon.svg';
import PhotoIcon from '../../../../../svg/photo_icon.svg';
import Arrow from '../../../../../svg/arrow-top-right-black.svg';

const PortfolioItems = ({ items }) => {


  let videoRef = React.useRef([]);
  videoRef.current = [];

  const handlePlayVideo = (e, index) => {
    e.preventDefault();
    videoRef.current.forEach((el, index) => {
      el.play();
    });
  };

  const handleStopVideo = () => {
    videoRef.current.forEach((el, index) => {
      el.pause();
    });
  };

  const addToRefs = (el) => {
    if (el && !videoRef.current.includes(el)) {
      videoRef.current.push(el);
    }
  };

  return (
    <div className="about-portfolio__items">
      {items.map((item, index) => {
        const count = ++index;
        const image = getImage(item.photo.localFile);
        return (
          <Link
            key={index}
            to={item.slug}
            onMouseEnter={(e) => handlePlayVideo(e, index)}
            onMouseLeave={handleStopVideo}
            className="about-portfolio__items-link"
          >
            <video
              src={`https://admin.zerotabletop.com${item.video?.url}`}
              ref={addToRefs}
              playsInline=""
              webkit-playsinline=""
              autoPlay={false}
              muted={true}
              loop={true}
              loading="lazy"
              className="about-portfolio__items-link__background about-portfolio__items-link__background-video"
            ></video>
            <GatsbyImage
              image={image}
              className="about-portfolio__items-link__background about-portfolio__items-link__background-photo"
              alt={item.title}
            />
            <span className="about-portfolio__items-link__wrapper">
              <span className="about-portfolio__items-link__count">
                {'0' + count + '.'}
              </span>
              <h3 className="about-portfolio__items-link__title">
                {item.title}
              </h3>
              <span className="about-portfolio__items-link__icon">
                {item.icon === true ? <VideoIcon /> : <PhotoIcon />}
              </span>
              <span className="about-portfolio__item-link__navigate">
                <span className="about-portfolio__item-link__navigate-title">
                  View Catalog
                </span>
                <Arrow className="about-portfolio__item-link__navigate-arrow" />
              </span>
            </span>
          </Link>
        );
      })}
    </div>
  );
};

PortfolioItems.propTypes = {
  items: PropTypes.array,
};

export default PortfolioItems;
