import React from 'react';
import PropTypes from 'prop-types';
import SectionHeader from '../../../UiComponents/SectionHeader/SectionHeader';
import TechnologyItems from './TechnologyItems/TechnologyItems';

const Technology = ({ dataTechnology }) => {

  return (
    <section className="about-technology" data-scroll-section>
      <div className="content-wrapper">
        <SectionHeader title={dataTechnology.title} description={'Equipment'} />
        <TechnologyItems items={dataTechnology.items} />
      </div>
    </section>
  );
};

Technology.propTypes = {
  dataTechnology: PropTypes.object,
};

export default Technology;
