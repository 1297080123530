import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout/layout';
import Seo from '../components/seo';

import {
  MainScreenFirst,
  History,
  OurNumbers,
  Portfolio,
  OurClients,
  Technology,
  CrumbsNav,
} from '../components/Pages/About';

// import { aboutData } from '../db/aboutData';
// import { contactsData } from '../db/contactsData';
import { aboutStatic } from '../db/aboutStatic';

const aboutPage = ({data}) => {
  return (
    <Layout>
      <Seo title={data.strapiAbout.seo_title} description={data.strapiAbout.seo_description} />
      <MainScreenFirst
        title={data.strapiAbout.main.title}
        socials={data.strapiContacts.Socials}
        showreel={data.strapiAbout.main.showreel}
        video={data.strapiAbout.main.video_cover}
        poster={data.strapiAbout.main.poster}
      />
      <History dataHistory={data.strapiAbout.History} />
      <OurNumbers numbers={data.strapiAbout.in_numbers} />
      <Portfolio dataPortfolio={data.strapiAbout.about_portfolio} />
      <Technology dataTechnology={data.strapiAbout.technology} />
      <OurClients clients={data.strapiAbout.clients} />
      <CrumbsNav dataCrumbs={aboutStatic.crumbsNav} />
    </Layout>
  );
};

export default aboutPage;

export const query = graphql`
  {
    strapiAbout {
      seo_title
      seo_description
      main {
        title
        showreel
        video_cover {
          url
        }
        poster {
          url
        }
      }
      History {
        title
        description
      }
      in_numbers {
        title
        value
      } 
      about_portfolio {
          title
          items {
            title
            slug
            icon
            video {
              url
            }
            photo {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1400
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      
      clients {
        title
        icons {
          icon {
            localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1400
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      technology {
        title
        items {
          title
          description
          image {
            localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1400
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
      strapiContacts {
        Socials {
          title
          link
        }
      }
    }
`