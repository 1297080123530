import React from 'react';
import PropTypes from 'prop-types';

import SectionHeader from '../../../UiComponents/SectionHeader/SectionHeader';
import PortfolioItems from './PortfolioItems/PortfolioItems';

const Portfolio = ({ dataPortfolio }) => {

  return (
    <section className="about-portfolio" data-scroll-section>
      <div className="content-wrapper">
        <SectionHeader title={dataPortfolio.title} description={'Portfolio'} />
      </div>
      <PortfolioItems items={dataPortfolio.items} />
    </section>
  );
};

Portfolio.propTypes = {
  dataPortfolio: PropTypes.object,
};

export default Portfolio;
