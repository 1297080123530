import React from 'react';
// import PropTypes from 'prop-types';
const ReactMarkdown = require("react-markdown");

const History = ({ dataHistory }) => {
  return (
    <section className="about-history second-section" id="second-section" data-scroll-section>
      <div className="content-wrapper">
        <h2 className="about-history__title">{dataHistory.title}</h2>
        <div className="about-history__marckdoun">
        <ReactMarkdown source={dataHistory.description} />
        </div>
      </div>
    </section>
  );
};

// History.propTypes = {};

export default History;
